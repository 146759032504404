import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby"

import { breakpoints, colors, fontTypes } from "../../styles/variables";

import TagContainer from "../tags/TagContainer";
import Tag from "../tags/Tag";

import { TagType, GatsbyImageSharpFluid_withWebpType } from "../../types";

import BackgroundImage from 'gatsby-background-image';

const StyledCard = styled(Link)`
    flex: 0 0 100%;
    margin-bottom: 28px;
    box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.2);
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    transition: box-shadow 0.2s cubic-bezier(.17,.67,.38,1), transform 0.1s cubic-bezier(0.4, 0.0, 1, 1);

    @media (min-width: ${breakpoints.ms}px) {
        flex: 0 0 calc(50% - (28px / 2));
        &:nth-child(odd) {
            margin-right: 28px;
        }
    }

    @media (min-width: ${breakpoints.lg}px) {
        flex: 0 0 calc((100% / 3) - (28px * 2 / 3));
        margin-right: 28px;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    &:after {
        content: "";
        float: left;
        padding-top: 100%;
    }

    &:hover {
        box-shadow: 0px 0px 40px -5px rgba(0,0,0,0.2);
        transform: scale(1.02);
        z-index: 100;
    }

    &:active {
        box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.2);
        transform: scale(.99);
    }

`;

const StyledBackground = styled(BackgroundImage)`
    height: calc(100% - 115px);
    &::before,
    &::after {
        border-radius: 10px 10px 0 0;
    }
`;


const Content = styled.div`
    background-color: ${colors.white};
    height: 115px;
    width: 100%;
    padding: 13px 15px;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    border-bottom: 15px solid ${colors.white};
`;

const Informations = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 17px;
`;

const Title = styled.div`
    ${fontTypes.cardTitle};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
`;
const Year = styled.div`
    ${fontTypes.cardYear}
    width: 20%;
    text-align: right;
`;

interface CardProps {
    title: string;
    year: number;
    image: GatsbyImageSharpFluid_withWebpType;
    tags: TagType[];
    slug: string;
};

const Card: React.FC<CardProps> = ({ title, year, image, tags, slug }) => {
    return (
        <StyledCard to={`/projekte/${slug}`}>
            <StyledBackground
                fluid={image.childImageSharp.fluid}
            />
                <Content>
                    <Informations>
                        <Title>{title}</Title>
                        <Year>{year}</Year>
                    </Informations>
                    <TagContainer>
                        {tags.map((tag) => (
                            <Tag
                                name={tag.name}
                                key={tag.id}/>
                            )
                        )}
                    </TagContainer>
                </Content>
        </StyledCard>
    )
};

export default Card;