import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { breakpoints } from "../styles/variables";

import Page from "../components/Page";
import DefaultLayout from "../layouts/default";
import CardContainer from "../components/cards/CardContainer";
import Card from "../components/cards/Card";

import { ProjectType, CategoryType } from "../types";

import { MenuProvider } from '../hooks/Menu/Provider';

export const query = graphql`
query HomePageQuery {
  projects: allProjectsJson(sort: {fields: creation, order: DESC}) {
    nodes {
      id
      name
      name_short
      lead
      text
      slug
      tags {
        id
        name
        slug
        stars
      }
      year
      creation
      image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  categories: allCategoriesJson {
    nodes {
      name
      id
      slug
    }
  }
}
`;

interface IndexPageProps {
  data: {
    projects: {
      nodes: ProjectType[]
    },
    categories: {
      nodes: CategoryType[]
    }
  }
}

const DesktopBreak = styled.br`
    display: none;

    @media (min-width: ${breakpoints.lg}px) {
        display: block;
    }
`;

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <MenuProvider>
      <DefaultLayout 
        title={"Adrian Hausammann"} 
        supLine={"Iterations with"} 
        showLogo={true} 
        subtitle={[
          "UX Designer, MAS HCID, Full Stack Developer,",
          <DesktopBreak/>,
          "Coffee Lover, Always Thinker, Idea Creator"
        ]}>
        <Page>
          <CardContainer>
            {data.projects.nodes.map((project) => (
                <Card 
                  title={project.name_short} 
                  year={project.year}
                  image={project.image}
                  tags={project.tags}
                  key={project.id}
                  slug={project.slug}/>
              )
            )}
          </CardContainer>
        </Page>
      </DefaultLayout>
    </MenuProvider>
  )
};

/*

        <FilterContainer>
          {data.categories.nodes.map((category) => (
              <Filter
                name={category.name}
                slug={category.slug}
                key={category.id}
              />
            )
          )}
        </FilterContainer>
*/

export default IndexPage;
