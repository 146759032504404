import * as React from "react";
import styled from "styled-components";

import { Container } from "../content";

const StyledCardContainer = styled(Container)`
    display: flex;
    flex-wrap: wrap;
`;

const CardContainer: React.FC = ({ children }) => (
  <StyledCardContainer>
    { children }
  </StyledCardContainer>
);

export default CardContainer;