import * as React from "react";
import styled from "styled-components";

import { dimensions } from "../styles/variables";

const StyledPage = styled.div`
  display: block;
  flex: 1;
  position: relative;
  padding: 0 ${dimensions.containerPadding}px 0 ${dimensions.containerPadding}px; 

  
  p {
    margin-top: 0;
  }
  ul {
    list-style-type: none;
    padding-left: 20px;
    li {
      margin-bottom: 12px;
      &:before {
        content: '- ';
        position: absolute;
        margin-left: -20px;
      }
    }
  }
`;

interface PageProps {
  className?: string;
}

const Page: React.FC<PageProps> = ({ children, className }) => (
  <StyledPage className={className}>{children}</StyledPage>
);

export default Page;
